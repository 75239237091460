:root {
    --primaryColor: #86c232;
    --primaryColorDark: #61892f;
    --offWhite: #Fafafa;
    --lightGrey: #6b6e70;
    --grey: #474b4f;
    --darkGrey: #222629;
    --alert: #ED4337;
}




/* --primaryColor: #355691;
    --primaryColorDark: #0F2D63;
    --primaryColorLight: #7B94C3;
    --secondaryColor: #F5B700;
    --offWhite: #F6FFF8;
    --lightGrey: #575859;
    --grey: #373738;
    --darkGrey: #2A2B2E;
    --alert: #ED4337; */
/*
yellow: FFB30F
blue: 437F97
lblue: 01295F


darkgrey: 1a1a1d



*/