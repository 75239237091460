/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.u-position-absolute {
    position: absolute;
}

.u-position-relative {
    position: relative;
}

.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__item--has-icon {
    position: relative;
}

.accordion__title {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
}

.accordion__title:hover {
    background-color: #ddd;
}

/* .accordion__body {
    padding: 20px;
    display: block;
    animation: fadein 0.35s ease-in;
}

.accordion__body--hidden {
    display: none;
    opacity: 0;
    animation: fadein 0.35s ease-in;
} */

.accordion__title > *:last-child,
.accordion__body > *:last-child {
    margin-bottom: 0;
}

.accordion__arrow {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 12px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -6px;
}

.accordion__arrow::after,
.accordion__arrow::before {
    display: block;
    position: absolute;
    top: 50%;
    width: 10px;
    height: 2px;
    background-color: currentColor;
    content: '';
}

.accordion__arrow::before {
    left: 4px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

[aria-expanded='true'] .accordion__arrow::before,
[aria-selected='true'] .accordion__arrow::before {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.accordion__arrow::after {
    right: 4px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

[aria-expanded='true'] .accordion__arrow::after,
[aria-selected='true'] .accordion__arrow::after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.accordion__arrow::before,
.accordion__arrow::after {
    transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes move-down {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    10% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    20% {
        -webkit-transform: translateY(5px);
                transform: translateY(5px);
    }
    30% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@keyframes move-down {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    10% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    20% {
        -webkit-transform: translateY(5px);
                transform: translateY(5px);
    }
    30% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@-webkit-keyframes move-up {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    10% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    20% {
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px);
    }
    30% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@keyframes move-up {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    10% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    20% {
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px);
    }
    30% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

.accordion__title--animated:hover .accordion__arrow {
    -webkit-animation-name: move-down;
            animation-name: move-down;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
}

.accordion__title--animated[aria-expanded='true']:hover .accordion__arrow {
    -webkit-animation-name: move-up;
            animation-name: move-up;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
}
:root {
    --primaryColor: #86c232;
    --primaryColorDark: #61892f;
    --offWhite: #Fafafa;
    --lightGrey: #6b6e70;
    --grey: #474b4f;
    --darkGrey: #222629;
    --alert: #ED4337;
}




/* --primaryColor: #355691;
    --primaryColorDark: #0F2D63;
    --primaryColorLight: #7B94C3;
    --secondaryColor: #F5B700;
    --offWhite: #F6FFF8;
    --lightGrey: #575859;
    --grey: #373738;
    --darkGrey: #2A2B2E;
    --alert: #ED4337; */
/*
yellow: FFB30F
blue: 437F97
lblue: 01295F


darkgrey: 1a1a1d



*/
/* Import styles for accordion here so i can override them in this file*/

/* --------- */
/* reset_css */
/* --------- */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
}

a{
  text-decoration: none;
}





/* ----------- */
/* general_css */
/* ----------- */

/* Make the app height always be atleast 100% of the viewport height */
body > #root > div {
  height: 100vh;
}

.container {
  max-width: 70rem;
  margin: 0 auto;
  padding: 0 2rem;
  min-height: 100%;
  display: grid;
  grid-template-rows: 5rem 1fr 6rem;
}

.header {
  background: var(--darkGrey);
  color:var(--offWhite);
  padding: 1rem;
  grid-row-start: 1;
  grid-row-end: 1;
  position: relative;
  display: grid;
  grid-template-columns: 14rem 1fr;
  align-items: center;
}

.header span {
  color: var(--primaryColor);
}

.header-link {
  color: var(--offWhite);
  display: inline-block;
  margin-right: 2rem;
  /* justify-self: end; */
  
}

.header-link-wrap {
  justify-self: end;
}

.sidebar {
  background-color: var(--grey);
  border-right: 2px solid var(--darkGrey);
  border-left: 2px solid var(--darkGrey);
  /* border-top: 1px solid var(--primaryColor); */
}

.sidebar-title {
  color: var(--offWhite);
  font-size: 1rem;
  margin-top: 1rem;
}

.content {
  padding: 1rem;
  background-color: var(--grey);
  color: var(--offWhite);
}

.wrapper {
  display: grid;
}

.footer {
  background: var(--darkGrey);
  color: var(--offWhite);
  padding: 1rem;
  width: 100%;
  text-align: center;
  grid-row-start: 3;
  grid-row-end: 3;
  display: grid;
  align-items: end; 
}

.footer p a {
  text-decoration: underline;
  color: #7B94C3;
}






/* -------- */
/* home_css */
/* -------- */

.jumbotron {
  margin: auto;
  /* height: 15rem; */
  background-color: var(--lightGrey);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;  
  grid-template-rows: 2fr 1fr 3fr 2fr;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  padding: 2rem;
  width: 95%;
  margin-top: 1rem;
}

.jumbotron h1 {
  /* grid-row: ; */
  grid-column: 1 / 3;
  align-self: center;
  justify-self: center;
  font-size: 3rem;
}
.jumbo-text {
  grid-column: 3 / 7;
  align-self: end;
  padding-bottom: .9rem;
  font-size: 1.3rem;
}

.jumbo-text span {
  color: var(--primaryColor)
}

.jumbo-card-title {
  grid-column: 1 / 7;
  align-self: end;
  font-size: 1.3rem;
}

.jumbo-card {
  background-color: var(--grey);
  font-size: 1.3rem;
  padding: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
  padding-left: 4rem;
}

.jumbo-card span {
  position: absolute;
  top: .4rem;
  left: 10px;
  font-size: 5rem;
  z-index: 1;
  color: var(--primaryColor)
}

.jumbo-card p {
  align-self: center;
}

.jumbo-card1 {
  grid-column: 1 / 3;
}
.jumbo-card2 {
  grid-column: 3 / 5;
}
.jumbo-card3 {
  grid-column: 5 / 7;
}

.jumbo-button {
  display: block;
  grid-column: 4 / 7;
  background-color: var(--primaryColor);
  border-radius: .2rem;
  text-align: center;
  font-size: 2rem;
  padding: 1rem 0;
  color: var(--darkGrey)
  
}








/* -------- */
/* wiki_css */
/* -------- */

.all-wikis-wrapper {
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 10rem;
  grid-gap: 1rem;
  padding: 1rem;
}

.wikis-card {
  background-color: var(--darkGrey);
  color: var(--offWhite);
  padding: .5rem;
  position: relative;
  border-radius: .1rem;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.wikis-card:hover {
  opacity: 0.8;
}

.wikis-card h2 {
  color: var(--primaryColor);
}

.wikis-card p {
  margin-top: .7rem;
}

.wikis-card h3 {
  font-size: 1.2rem;
  position: absolute;
  bottom: .3rem;
  left: .7rem;
}

.wikis-card h4 {
  position: absolute;
  bottom: .3rem;
  right: .7rem;
  font-size: 2rem;
  color: var(--offWhite)
}


.wikis-book-icon {
  color: var(--primaryColorDark)
}




.wiki-wrapper {
  grid-template-columns: 12rem 1fr;
}

/* Hide all text by default */
.text-content {
  display: none;
}

.text-content p {
  margin-bottom: .7rem;
}



.prev-page-link {
  color: var(--offWhite);
  display: inline-block;
  background-color: var(--darkGrey);
  padding: .3rem .5rem;
  font-size: .8rem;
  border-radius: .1rem;
  position: relative;
  bottom: .7rem;
  right: .72rem;
}

.prev-page-link:hover {
  opacity: 0.8;
}






/* progressInput-css */

.progress-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.progress-ul li {
  width: 100%;
  
}

.progress-ul li:first-child {
  border-top: 1px solid var(--darkGrey) !important;
}


.progress-ul input {
  display: none;
}

.progress-ul label {
  background-color: var(--lightGrey);
  color: var(--offWhite);
  display: block;
  display: grid;
  padding: .2rem 0;
  border-bottom: 2px solid var(--darkGrey);
  cursor: pointer;
  font-size: .8rem;
  position: relative;
  padding-left: 1.4rem;
}


.progress-checked {
  background-color: var(--darkGrey) !important;
  border-bottom: 2px solid var(--primaryColor) !important;
  /* border-left: 3px solid var(--primaryColor) !important; */
}

.label-hover {
  /* border-left: 3px solid var(--primaryColor) !important; */
  border-bottom: 2px solid var(--primaryColor) !important;
  /* background-color: var(--grey) !important; */
}

.progress-label:hover .progress-checkbox {
  /* background-color: var(--lightGrey) !important; */
}
.label-hover .progress-checkbox {
  background-color: var(--primaryColor) !important;
}

.progress-label:hover {
  /* background-color: var(--grey) !important; */
}

.progress-label:hover .progress-checkbox span {
  /* border-color: var(--primaryColor) */
}


.progress-checkbox {
  position: absolute;
  top: .2rem;
  left: .2rem;
  height: 1rem;
  width: 1rem;
  background-color: var(--grey);
  align-self: center
}

.progress-checkbox-checked {
  background-color: var(--primaryColor) !important;
}

.progress-checkbox-mark {
  content: "";
  position: absolute;
  left: 5px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}




/* category-css */

.accordion__item {
  cursor: pointer;
}

.accordion__title {
  height: 2rem;
  padding: .4rem;
  margin: auto;
  font-size: .7rem;
  background-color: var(--darkGrey);
  color: var(--offWhite);
}

.accordion__title:focus {
    outline: none !important;
}

.accordion__title:hover {
    background-color: var(--primaryColorDark);

}
.accordion__title:hover .accordion__arrow::before, 
.accordion__title:hover .accordion__arrow::after {
  background-color: var(--offWhite);
}

.accordion__body {
  padding: .5rem 1rem;
  margin: auto;
}

.category-link {
  display: block;
  color: var(--offWhite);
  text-decoration: underline;
  -webkit-text-decoration-color: var(--primaryColor);
          text-decoration-color: var(--primaryColor);
  font-size: .9rem;
  padding: .06rem 0;
}

.accordion__item {
    position: relative;
}

.accordion__arrow {
    display: inline-block;
    width: 24px;
    height: 12px;
    position: absolute;
    top: 1rem;
    right: .4rem;
}

.accordion__arrow::after,
.accordion__arrow::before {
    width: 8px;
    height: 2px;
    background-color: var(--primaryColor);
}

.accordion__arrow::before {
    left: 6px;
}

.accordion__arrow::after {
    right: 6px;
}


